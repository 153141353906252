export const OPEN_SIDE_MENU = 'OPEN_SIDE_MENU';
export const CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU';
export const SUBMIT_CREATE_GROUP_REQUEST = 'SUBMIT_CREATE_GROUP_REQUEST';
export const SUBMIT_UPDATE_GROUP_REQUEST = 'SUBMIT_UPDATE_GROUP_REQUEST';
export const GET_GROUP_DETAILS_REQUEST = 'GET_GROUP_DETAILS_REQUEST';
export const REMOVE_GROUP_REQUEST = 'REMOVE_GROUP_REQUEST';
export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const CLEAR_CREATE_GROUP_FORM = 'CLEAR_CREATE_GROUP_FORM';
export const SET_INITIAL_VALUES_FORM = 'SET_INITIAL_VALUES_FORM';

export function openSideMenu() {
  return {
    type: OPEN_SIDE_MENU,
  };
}

export function closeSideMenu() {
  return {
    type: CLOSE_SIDE_MENU,
  };
}

export function submitCreateGroupRequest(values) {
    return {
        type: SUBMIT_CREATE_GROUP_REQUEST,
        values,
    };
}

export function submitUpdateGroupRequest(groupId, values) {
  return {
      type: SUBMIT_UPDATE_GROUP_REQUEST,
      groupId,
      values,
  };
}

export function removeGroupRequest(groupId) {
  return {
      type: REMOVE_GROUP_REQUEST,
      groupId,
  };
}

export function getGroups() {
  return {
    type: GET_GROUPS_REQUEST,
  };
}

export function getGroupDetails(groupId) {
  return {
    type: GET_GROUP_DETAILS_REQUEST,
    groupId,
  };
}

export function clearCreateGroupForm() {
  return {
    type: CLEAR_CREATE_GROUP_FORM,
  };
}

export function setInitialValuesForm(values) {
  return {
    type: SET_INITIAL_VALUES_FORM,
    values,
  };
}
