import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getGroups } from '../../actions/groupActions';
import GroupTable from '../../components/Tables/GroupTable';

const GroupsTab = ({
  isActive,
  doGetGroups,
  groupList,
}) => {
  useEffect(() => {
    if (isActive) doGetGroups();
  }, [isActive]);

  return (
    <Row className="mt-2">
      <Col xs={12}>
        {groupList && isActive && (
          <GroupTable
            groups={groupList}
            onEditClick={() => {}}
            onDeleteClick={() => {}}
            isEditable={false}
          />
      )}
      </Col>
    </Row>
  );
};

GroupsTab.propTypes = {
  isActive: PropTypes.bool,
  doGetGroups: PropTypes.func,
  groupList: PropTypes.instanceOf(Array),
};
const mapStateToProps = ({ groups }) => ({
  groupList: groups.groupList,
});
const mapDispatchToProps = (dispatch) => ({
  doGetGroups: () => dispatch(getGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTab);
