import Qs from 'qs';

import ENV_VARS from '../utils/envVars';

export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';

export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE';

export const REMOVE_GROUP_SUCCESS = 'REMOVE_GROUP_SUCCESS';
export const REMOVE_GROUP_FAILURE = 'REMOVE_GROUP_FAILURE';

export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAILURE = 'GET_GROUP_DETAILS_FAILURE';

export function getGroupsApi() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/groups',
    method: 'GET',
    params: {},
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_GROUPS_SUCCESS,
    failureAction: GET_GROUPS_FAILURE,
  };
}

export function addGroupApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/groups',
    method: 'POST',
    data: {
      name: payload.name,
      admins: payload.admins,
      members: payload.members
    },
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_GROUP_SUCCESS,
    failureAction: ADD_GROUP_FAILURE,
  };
}

export function editGroupApi(id, payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/groups/${id}`,
    method: 'PUT',
    data: {
      name: payload.name,
      admins: payload.admins,
      members: payload.members
    },
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: EDIT_GROUP_SUCCESS,
    failureAction: EDIT_GROUP_FAILURE,
  };
}

export function removeGroupApi(id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/groups/${id}`,
    method: 'DELETE',
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: REMOVE_GROUP_SUCCESS,
    failureAction: REMOVE_GROUP_FAILURE,
  };
}

export function getGroupDetailsApi(id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/groups/${id}`,
    method: 'GET',
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_GROUP_DETAILS_SUCCESS,
    failureAction: GET_GROUP_DETAILS_FAILURE,
  };
}
