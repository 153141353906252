import { put, take, race, all } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import { GET_GROUP_DETAILS_REQUEST, GET_GROUPS_REQUEST, REMOVE_GROUP_REQUEST, SUBMIT_CREATE_GROUP_REQUEST, SUBMIT_UPDATE_GROUP_REQUEST } from '../actions/groupActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';
import { ADD_GROUP_FAILURE, ADD_GROUP_SUCCESS, addGroupApi, EDIT_GROUP_FAILURE, EDIT_GROUP_SUCCESS, editGroupApi, GET_GROUP_DETAILS_FAILURE, GET_GROUP_DETAILS_SUCCESS, GET_GROUPS_FAILURE, GET_GROUPS_SUCCESS, getGroupDetailsApi, getGroupsApi, REMOVE_GROUP_FAILURE, REMOVE_GROUP_SUCCESS, removeGroupApi } from '../apiActions/groupApiActions';

export default function* groupSagas() {
  yield all([
    getGroupsSaga(),
    addGroupsSaga(),
    editGroupsSaga(),
    getGroupDetailsSaga(),
    removeGroupsSaga(),
  ]);
}

function* getGroupsSaga() {
  while (true) {
    yield take(GET_GROUPS_REQUEST);
    yield put(getGroupsApi());
    const { failure } = yield race({
      success: take(GET_GROUPS_SUCCESS),
      failure: take(GET_GROUPS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* addGroupsSaga() {
  while (true) {
    const { values } = yield take(SUBMIT_CREATE_GROUP_REQUEST);
    const { name, members } = values;
    if (name.length === 0 || members.length === 0) {
      return;
    }

    yield put(addGroupApi(values));

    const { success, failure } = yield race({
      success: take(ADD_GROUP_SUCCESS),
      failure: take(ADD_GROUP_FAILURE),
    });

    if (success) {
      yield put(getGroupsApi());
      yield put(showAlert('Success', 'Group created successfully!', 'success'));
    } else {
      yield put(showAlert('Oops!', 'Something failed. Please try again!'));
    }
  }
}

function* editGroupsSaga() {
  while (true) {
    const { groupId, values } = yield take(SUBMIT_UPDATE_GROUP_REQUEST);
    const { name, members } = values;
    if (name.length === 0 || members.length === 0) {
    return;
    }

    yield put(editGroupApi(groupId, values));

    const { success, failure } = yield race({
    success: take(EDIT_GROUP_SUCCESS),
    failure: take(EDIT_GROUP_FAILURE),
    });

    if (success) {
    yield put(getGroupsApi());
    yield put(showAlert('Success', 'Group edited successfully!', 'success'));
    } else {
    yield put(showAlert('Oops!', 'Something failed. Please try again!'));
    }
  }
}

function* getGroupDetailsSaga() {
  while (true) {
    const { groupId } = yield take(GET_GROUP_DETAILS_REQUEST);

    yield put(getGroupDetailsApi(groupId));

    const { failure } = yield race({
      success: take(GET_GROUP_DETAILS_SUCCESS),
      failure: take(GET_GROUP_DETAILS_FAILURE),
    });

    if (failure) {
      yield put(showAlert('Oops!', 'Something failed. Please try again!'));
    }
  }
}

function* removeGroupsSaga() {
  while (true) {
    const { groupId } = yield take(REMOVE_GROUP_REQUEST);
    yield put(removeGroupApi(groupId));
    yield put(getGroupsApi());

    const { success, failure } = yield race({
      success: take(REMOVE_GROUP_SUCCESS),
      failure: take(REMOVE_GROUP_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }

    if (success) {
      yield put(getGroupsApi());
      yield put(showAlert('Success', 'Group removed successfully!', 'success'));
    } else {
      yield put(showAlert('Oops!', 'Something failed. Please try again!'));
    }
  }
}
