import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import TeamDashboardContainer from '../../containers/Teams/TeamDashboardContainer';
import { isTeamUser } from '../../utils/authUtils';
import { getEngagementSummary, getClientStatusAndGoalDistribution, getEngagementSummaryCsv, clearEngagementSummaryCsv } from '../../actions/teamActions';
import { getGroupDetails, getGroups } from '../../actions/groupActions';
import renderSelectForField from '../../forms/helpers/renderSelectForField';

const now = moment();
let dashboardStartDate = now.clone().add(-30, 'days');
let dashboardEndDate = now;

const DashboardGroup = ({
  doGetEngagementSummary,
  doGetClientStatusAndGoalDistribution,
  team,
  user,
  doGetEngagementSummaryCsv,
  doClearEngagementSummaryCsv,
  doGetGroupDetails,
  doGetGroups,
  groupList,
  history,
  match: {
    params: { group_id },
  } }) => {
  const { roles } = user;
  const [groupId, setGroupId] = useState(group_id);

  const onMount = async () => {
    await doGetEngagementSummary(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      groupId,
      ['active']
    );
    await doGetClientStatusAndGoalDistribution();
    await doGetGroupDetails(groupId);
    await doGetGroups();
  };

  useEffect(() => {
    onMount();
  }, [groupId]);

  const updateEngagementSummary = (team_id, startDate, endDate, sortBy, order, page, showCount, clearData = false) => {
    dashboardStartDate = startDate;
    dashboardEndDate = endDate;
    doGetEngagementSummary(
      team_id,
      dashboardStartDate,
      dashboardEndDate,
      sortBy,
      order,
      page,
      showCount,
      clearData,
      groupId,
      ['active']
    );
  };

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <ContentWrapper>
      <Row>
        <Col xs={12}>
          <PageTitle title="Dashboard" />
          {!isTeamUser(roles) && (
            <div className="main-content-container">
              <div className="mb-3">{roles}-specific dashboard will go here...</div>
            </div>
          )}
        </Col>
      </Row>
      {isTeamUser(roles) && team && team.engagementSummary && team.membership && team.counts && team.goal_distribution ? (
        <div className="main-content-container">
          <Row xs={12} className="justify-content-between mb-4">
            <Col xs={8}>
              <Row className="align-items-center">
                <button onClick={handleBackClick} className="btn-back" aria-label="Go back">
                  <img src="/img/icons/arrow_back.svg" alt="Arrow Back" />
                </button>
                <h2 className="mt-2">{groupList.find((group) => group.id === groupId)?.name}</h2>
              </Row>
            </Col>

            <Col xs={4}>
              <Form
                onSubmit={() => {}}
                initialValues={{ group_id: groupId }}
                keepDirtyOnReinitialize
                render={() => (
                  <Field
                    name="group_id"
                    label="Select a different group to view*"
                    component={renderSelectForField}
                    initialValue={groupId}
                    {...{
                      itemProps: {},
                      input: {
                        initialValue: groupId,
                        onChange: (e) => setGroupId(e),
                        value: groupId,
                      },
                      inputProps: {
                        options: groupList.map((group) => ({
                          value: group.id,
                          label: group.name,
                        })),
                        isClearable: false,
                      },
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          <TeamDashboardContainer
            team={team}
            isTeamOwner={true}
            doGetEngagementSummary={updateEngagementSummary}
            startDate={dashboardStartDate}
            endDate={dashboardEndDate}
            doGetEngagementSummaryCsv={doGetEngagementSummaryCsv}
            doClearEngagementSummaryCsv={doClearEngagementSummaryCsv}
            showStatistics={false}
          />
        </div>
      ) : (
        <div className="mt-5">
          <Loading />
        </div>
      )}
    </ContentWrapper>
  );
};

DashboardGroup.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  doGetEngagementSummary: PropTypes.func,
  doGetClientStatusAndGoalDistribution: PropTypes.func,
  doGetEngagementSummaryCsv: PropTypes.func,
  doClearEngagementSummaryCsv: PropTypes.func,
};
const mapStateToProps = (state) => ({
  team: state.team,
  user: state.auth.user,
  groupList: state.groups.groupList,
});
const mapDispatchToProps = (dispatch) => ({
  doGetClientStatusAndGoalDistribution: () => dispatch(getClientStatusAndGoalDistribution()),
  doGetEngagementSummaryCsv: (team_id, start_date, end_date) => dispatch(getEngagementSummaryCsv(team_id, start_date, end_date)),
  doClearEngagementSummaryCsv: () => dispatch(clearEngagementSummaryCsv()),
  doGetEngagementSummary: (
    team_id,
    dashboardStartDate,
    dashboardEndDate,
    sortBy,
    order,
    page,
    showCount,
    clearData,
    groupId,
    user_status
  ) =>
    dispatch(
      getEngagementSummary(
        team_id,
        dashboardStartDate,
        dashboardEndDate,
        sortBy,
        order,
        page,
        showCount,
        clearData,
        groupId,
        user_status
      )
    ),
  doGetGroupDetails: (groupId) => dispatch(getGroupDetails(groupId)),
  doGetGroups: () => dispatch(getGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGroup);
