import { all } from 'redux-saga/effects';
import apiCallerSagas from './apiCallerSaga';
import authSagas from './authSaga';
import signUpSagas from './signUpSaga';
import initSagas from './initSaga';
import layoutSagas from './layoutSaga';
import globalSagas from './globalSaga';
import userSagas from './userSaga';
import teamSagas from './teamSaga';
import printSagas from './printSaga';
import foodSagas from './foodSaga';
import challengeSagas from './challengeSaga';
import integrationSagas from './integrationSaga';
import groupSagas from './groupSaga';

export default function* rootSaga() {
  yield all([
    apiCallerSagas(),
    initSagas(),
    authSagas(),
    signUpSagas(),
    layoutSagas(),
    globalSagas(),
    userSagas(),
    teamSagas(),
    printSagas(),
    foodSagas(),
    challengeSagas(),
    integrationSagas(),
    groupSagas(),
  ]);
}
